<template>
  <div class="dashboard">
    <TheHeader></TheHeader>
    <SiteNav></SiteNav>
    <div class="px-3 pt-3 bg-lines bg-cover bg-fixed bg-center min-h-screen">

      <NotAvailable v-if="!user.company.features.carpooling.available" name="Mitfahrzentrale"></NotAvailable>

      <div class="md:container md:mx-auto" v-else>
      <div
        class="
          alert alert-default
          text-center
          my-3
          p-3
          rounded-lg
          text-black
          bg-gray-200
        "
      >
        <p>
          Die Mitfahrzentrale ist in Deinem Verkehrsbetrieb noch nicht
          verfügbar.
        </p>
      </div>

      <div class="text-center text-shadow">
        <h1 class="text-3xl">Coming soon</h1>
        <h1 class="text-xl">Mitfahrzentrale</h1>
      </div>

      <div class="mt-12 w-full text-center  text-shadow">
        <p class="text-lg font-bold">
          Teste diese Funktion exklusiv, sobald sie verfügbar ist.
        </p>
        <button
          v-show="!isLoading && !getNotification"
          type="button"
          :disabled="isLoadingSubscription"
          @click="subscribeToNotification()"
          class="
            w-full
            sm:w-max
            mx-auto
            text-center
            button-filled
            text-white
            bg-blue-900
            hover:bg-blue-800
            rounded-lg
            p-3
            m-3
          "
        >
          <font-awesome-icon
            icon="comment-alt"
            class="icon animate-bounce mr-2"
          />
          Benachrichtige mich
        </button>
        <p v-show="!isLoading && getNotification" class="color-success">
          <font-awesome-icon
            icon="comment-alt"
            class="icon mr-2"
          /> Wir werden Dich benachrichtigen.
        </p>
      </div>

      <div
        class="
          py-8
          text-base
          leading-6
          space-y-4
          sm:text-lg
          sm:leading-7
          sm:px-12
          px-6
          pt-12
        "
      >
        <!-- <img class=" sm:w-40 sm:h-40 w-24 h-24 mb-8" src="@/assets/SBFC_Logo_neu.png"> -->
        <p class="pb-8 text-2xl text-shadow text-center  text-shadow">
          Mitfahren<span class="font-bold text-blue-600 text-3xl">,</span> entspannter ankommen <span class="font-bold text-blue-600 text-3xl">und</span> Spritkosten teilen<span class="font-bold text-blue-600 text-3xl">.</span>
          <!-- Ich bin ein
          <span class="font-bold text-blue-600 text-3xl">geniales</span>
          Argument, dass Nutzer diese Funktion
          <span class="font-bold text-blue-600 text-3xl">lieben</span> werden. -->
        </p>

        <ul class="list-none sm:space-y-2 space-y-4">
            <li class="p-6 bg-gray-900 rounded-3xl">
              <div class="flex items-start">
                <span class="h-6 flex items-center sm:h-8">
                  <svg
                    class="flex-shrink-0 h-9 w-9 text-blue-800"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <p class="ml-2 my-auto font-bold">Betriebsintern</p>
              </div>
              <p class="ml-2 mt-2 my-auto">Du fährst ausschließlich mit Kolleginnen und Kollegen aus Deinem Verkehrsbetrieb.</p>
            </li>
            <li class="p-6 bg-gray-900 rounded-3xl">
              <div class="flex items-start">
                <span class="h-6 flex items-center sm:h-8">
                <svg
                  class="flex-shrink-0 h-9 w-9 text-blue-800"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
              <p class="ml-2 my-auto font-bold">Go Green</p>
              </div>
              <p class="ml-2 mt-2 my-auto">Mach die Umwelt ein bisschen grüner. Durch Fahrgemeinschaften trägst Du Deinen Teil zur Nachhaltigkeit bei.</p>
            </li>
            <li class="p-6 bg-gray-900 rounded-3xl">
              <div class="flex items-start">
                <span class="h-6 flex items-center sm:h-8">
                  <svg
                    class="flex-shrink-0 h-9 w-9 text-blue-800"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
                <p class="ml-2 my-auto font-bold">Ökonomisch</p>
              </div>
              <p class="ml-2 mt-2 my-auto">Reduziere Tankkosten und sorge für eine bessere Parkplatzsituation.</p>
            </li>
          </ul>
        <p class="pt-8 text-center text-shadow">
          Bilde eine Fahrgemeinschaft mit Kolleginnen und Kollegen innerhalb Deines Verkehrsbetriebs. Biete Fahrten an und suche Fahrten. Kombiniere Fahrten, die zur selben Zeit stattfinden und die gleiche Route haben.
        </p>
      </div>

      <div class="mt-12 pb-16 w-full text-center text-shadow">
        <p class="text-lg font-bold">Interessiert diese Funktion zu Testen?</p>
        <button
          v-show="!isLoading && !getNotification"
          type="button"
          :disabled="isLoadingSubscription"
          @click="subscribeToNotification()"
          class="
            w-full
            sm:w-max
            mx-auto
            text-center
            button-filled
            text-white
            bg-blue-900
            hover:bg-blue-800
            rounded-lg
            p-3
            m-3
          "
        >
          <font-awesome-icon
            icon="comment-alt"
            class="icon animate-bounce mr-2"
          />
          Benachrichtige mich
        </button>
        <p v-show="!isLoading && getNotification"  class="color-success">
          <font-awesome-icon
            icon="comment-alt"
            class="icon mr-2"
          /> Wir werden Dich benachrichtigen.
        </p>
      </div>
      </div>
    </div>
  </div>
</template>


<script>
import SiteNav from "@/components/TheNavigation";
import TheHeader from "@/components/TheHeader";
import NotAvailable from "@/components/NotAvailable";


import { mapGetters } from "vuex";

export default {
  name: "Dashboard",
  components: {
    SiteNav,
    TheHeader,
    NotAvailable
  },
  data() {
    return {
      isLoading: true,
      isLoadingSubscription: false,
      getNotification: false
    };
  },
  methods: {
    subscribeToNotification() {
      this.isLoadingSubscription = true;
      
      this.$store.dispatch("setCarpoolingComingSoon").then((a) => {
        if (a.status == true) {
          this.getNotification = true
        } else {
          console.error("Error with setCarpoolingComingSoon")
        }
      });
    }
  },
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    this.$store.dispatch("fetchCarpoolingComingSoonForUser").then((a) => {
      if(a.status) {
        this.isLoading = false;
        if (a.response.data.length == 0) {
          this.getNotification = false
        } else {
          this.getNotification = true
        }
      }
    })
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>